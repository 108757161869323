$primary: #0048d3;
$light: #fafafa;
$border-color: rgb(241, 243, 244);

@import "react-toastify/scss/main.scss";
@import url("https://fonts.googleapis.com/css2?family=Be+Vietnam+Pro:wght@300;400;500;600;700&display=swap");

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Be Vietnam Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --toastify-color-info: $primary;
  --toastify-color-success: #07bc0c;
  --toastify-color-warning: #f1c40f;
  --toastify-color-error: #e74c3c;
  --toastify-font-family: "Be Vietnam Pro", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  line-height: 1.8;
}
.Toastify {
  font-size: 14px;
}
a {
  text-decoration: none;
}
.main {
  background-color: white;
  border: 1px solid $border-color;
  overflow: hidden;
}
a {
  color: $primary;
}
.tableTools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  padding-left: 12px;
  padding-right: 12px;
  border-bottom: 1px solid $border-color;
}
.countdown {
  display: flex;
  gap: 0.5rem;
  flex-direction: row;
  flex-wrap: wrap;
}
.countdownItem {
  border: 1px solid $primary;
  color: $primary;
  padding: 0.5rem;
  display: block;
  line-height: 1;
  .countdownValue {
    font-size: 0.875rem;
    line-height: inherit;
    font-weight: bold;
  }
}

.bgLight {
  background-color: $light;
}
.postFiles .MuiListItem-root {
  border: 1px solid $border-color;
  padding: 0 0.5rem;
  margin-bottom: 0.75rem;
}
.authWrapper {
  width: 100%;
  height: 100vh;
  background: url("../assets/images/bg.png") white no-repeat center right 500px;
  background-size: cover;
  display: flex;
  justify-content: flex-end;
  .authBox {
    max-width: 500px;
    padding: 200px 100px;
    flex: 1;
    height: 100vh;
    background-color: white;
  }
}
.firstUpper:first-letter {
  text-transform: uppercase;
}

.welcomeBox {
  background-color: #f5f9ff;
  padding: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}
.welcomeBox img {
  max-width: 200px;
}
.projectCard {
  background-color: white;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-image: none;
  overflow: hidden;
  position: relative;
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  z-index: 0;
  padding: 1.25rem;
}
.infoBox .MuiTypography-root {
  font-size: 13px !important;
  font-weight: 500;
}

.MuiDataGrid-footerContainer {
  display: none !important;
}
.vendorCard {
  border-color: $border-color;
  border-style: solid;
  border-width: 1px;
  padding: 20px;
}
